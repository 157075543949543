import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fIndianCurrencyOLD(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0');
}

export function fIndianCurrency(number) {
  return Intl.NumberFormat('en-IN', {maximumFractionDigits: 0}).format(number)
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fDobToAge(dateString) {
    if (!dateString) {
      return 0
    }
    var today = new Date();
    // var parts = dateString.split('-')
    // var birthDate = new Date(parts[0],parts[1] - 1,parts[w]);
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}