import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import * as api from '../../_apis_/service'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  mobile: null,
  orderId: false,
  amount: null,
  method: null,
  orderDetails: null,
  paymentDetails: null,
  paymentStarted: false,
  paymentCompleted: false,
  paymentTxnId: false,
  providerId: null,
  providerDetails: null,
  payNowCheckoutSessionId: null,

};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    createPaymentOrderSuccess(state, action) {
      state.isLoading = false;
      state.orderDetails = action.payload;
      state.paymentStarted = true;
      state.amount = action.payload.amount;
      state.orderId = action.payload.id;
      state.method = 'razorpay';
    },

    // GET PROFILE
    createStripePaymentSuccess(state, action) {
      state.isLoading = false;
      state.orderDetails = action.payload;
      state.paymentStarted = true;
      state.amount = action.payload.amount;
      state.orderId = action.payload.id;
      state.method = 'stripe';
    },

    // GET PROFILE
    updatePaymentStatusSuccess(state, action) {
      state.isLoading = false;
      state.paymentDetails = action.payload;
      state.paymentCompleted = true;
    },

    // GET PROVIDER ID
    getProviderIdSuccess(state, action) {
      state.isLoading = false;
      state.providerId = action.payload;
    },

    // GET PROVIDER DETAILS
    getProviderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.providerDetails = action.payload;
      state.providerId = action.payload.providerId;
    },

    // CREATE RAZORPAY SESSION
    createRazorpaySessionSuccess(state, action) {
      state.isLoading = false;
      state.payNowCheckoutSessionId = action.payload;
    },

    // UPDATE RAZORPAY SESSION
    updateRazorpaySessionSuccess(state, action) {
      state.isLoading = false;
    },

    // SEND SCAN EVENT
    sendScanEventSuccess(state, action) {
      state.isLoading = false;
    },

    // GET PROFILE
    getPaymentStatusSuccess(state, action) {
      state.isLoading = false;
      state.paymentDetails = action.payload;
      state.paymentCompleted = true;
    },

    
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getOrderId } = slice.actions;

// ----------------------------------------------------------------------

export function getAccount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/account/me');
      dispatch(slice.actions.getAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPaymentOrder(applicationId, sessionId, paylater, cardless, creditCards) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/razorpay/create_order', {
        applicationId,
        sessionId,
        paylater,
        cardless,
        creditCards
      });
      dispatch(slice.actions.createPaymentOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPaynowOrder(providerId, sessionId, values) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/razorpay/create_paynow_order', {providerId, sessionId, 'data': values});
      dispatch(slice.actions.createPaymentOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePaynowOrder(providerId, sessionId, orderId, values) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/razorpay/update_paynow_order', {providerId, sessionId, orderId, 'data': values});
      dispatch(slice.actions.createPaymentOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createStripePayment(amount) {

  const mobile = window.localStorage.getItem('mobile');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/stripe/create_payment', {'mobile': mobile, 'amount': amount});
      dispatch(slice.actions.createStripePaymentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePaymentStatus(applicationId, sessionId, paymentResponse) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/razorpay/update_status', {
        applicationId, 
        sessionId,
        'paymentObject': paymentResponse
      });
      dispatch(slice.actions.updatePaymentStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePaynowStatus(providerId, sessionId, paymentResponse) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/razorpay/update_paynow_status', {
        providerId,
        sessionId,
        'paymentObject': paymentResponse
      });
      dispatch(slice.actions.updatePaymentStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaymentStatus(sessionId) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/manage/get_status', {
        sessionId
      });
      dispatch(slice.actions.getPaymentStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateStripePaymentStatus(paymentResponse) {

  const mobile = window.localStorage.getItem('mobile');
  console.log('Hitting update Payment Status', mobile, paymentResponse)
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/payments/stripe/update_status', {'mobile': mobile, 'paymentIntent': paymentResponse});
      dispatch(slice.actions.updatePaymentStatusSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderFromUpi(upiAccount) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/manage/get_provider_from_upi', {upiAccount});
      // dispatch(slice.actions.getProviderIdSuccess(response.data));
      dispatch(slice.actions.getProviderDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderDetails(providerId) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/manage/get_provider_details', {providerId});
      dispatch(slice.actions.getProviderDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createRazorpayPaymentSession(providerId) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/manage/create_razorpay_session', {providerId});
      dispatch(slice.actions.createRazorpaySessionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRazorpayPaymentSession(sessionId, values) {

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/manage/update_razorpay_session', {sessionId, 'data': values});
      dispatch(slice.actions.updateRazorpaySessionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendScanEvent(event, data) {

  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/scanner/update_event', {event, data});
      dispatch(slice.actions.sendScanEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendCheckoutEvent(event, data) {

  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/checkout/scanner/update_event', {event, data});
      dispatch(slice.actions.sendScanEventSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

